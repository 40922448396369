import { Box } from "@mui/material";
import React, { useState, useRef, useCallback } from "react";
import {FlexBetween} from "../../components/FlexBox";
import FilterItem from "../../components/Menus/FilterItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TuneIcon from "@mui/icons-material/Tune";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import PropertyCard from "../../components/cards/PropertyCard";
import useProperties from "../../services/hooks/useProperties";
import ListingsLoader from "../../components/loaders/ListingsLoader";
import PropertyTypeFilter from "../../components/cards/PropertyTypeFilter";
import PriceFilter from "../../components/cards/PriceFilter";

const Listings = () => {
	const [pageNum, setPageNum] = useState(1);
	const { isLoading, isError, error, results, hasNextPage } = useProperties(pageNum);

	const intObserver = useRef();
	const lastPostRef = useCallback(
		(value) => {
			if (isLoading) return;
			if (intObserver.current) intObserver.current.disconnect();

			intObserver.current = new IntersectionObserver((value) => {
				if (value[0].isIntersecting && hasNextPage) {
					console.log("We are near the last value!");
					setPageNum((prev) => prev + 1);
				}
			});

			if (value) intObserver.current.observe(value);
		},
		[isLoading, hasNextPage]
	);

	if (isError) return <p className="center">Error: {error.message}</p>;

	const content = results.map((value, i) => {
		if (results.length === i + 1) {
			return (
				<Grid xs={12} sm={4} md={3}>
					<PropertyCard
						ref={lastPostRef}
						posted={value.posted}
						rent={value.rent}
						kitchen={value.kitchen}
						bath={value.bath}
						terms={value.terms}
						image={value.image}
						id={value.id}
						location={value.location}
					/>
				</Grid>
			);
		}
		return (
			<Grid xs={12} sm={4} md={3}>
				<PropertyCard
					posted={value.posted}
					id={value.id}
					rent={value.rent}
					kitchen={value.kitchen}
					bath={value.bath}
					terms={value.terms}
					image={value.image}
					location={value.location}
				/>
			</Grid>
		);
	});
	return (
		<Box>
			{/* FILTER PART */}
			<FlexBetween>
				<FlexBetween gap="1rem">
					<FilterItem text="Location" iconOne={<KeyboardArrowUpIcon />} iconTwo={<KeyboardArrowDownIcon />} />
					<FilterItem content={<PriceFilter />} text="Prices" iconOne={<KeyboardArrowUpIcon />} iconTwo={<KeyboardArrowDownIcon />} />
					<FilterItem content={<PropertyTypeFilter />} text="Property Type" iconOne={<KeyboardArrowUpIcon />} iconTwo={<KeyboardArrowDownIcon />} />
				</FlexBetween>
				<FlexBetween gap="1rem">
					<FilterItem text="Advanced Filter" startIcon={<TuneIcon />} />
					<FilterItem text="Create Alerts" startIcon={<NotificationsActiveIcon />} />
				</FlexBetween>
			</FlexBetween>

			{/* CONTENT PART */}

			<Box sx={{ marginTop: "30px" }}>
				{isLoading ? (
					<ListingsLoader />
				) : (
					<Grid container spacing={4}>
						{content}
					</Grid>
				)}
				{isLoading && <ListingsLoader />}
				<Box style={{ textAlign: "center" }}>
					<p>
						<a href="#top">Back to Top</a>
					</p>
				</Box>
			</Box>
		</Box>
	);
};

export default Listings;
