import { FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import React from "react";
import { FlexCenter } from "../FlexBox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};


const PriceFilterForm = (props) => {
	return (
		<FormControl sx={{ width: "170px" }} size="small">
			<InputLabel>{props.label}</InputLabel>
			<Select
				label={props.label}
				renderValue={(selected) => (
					<FlexCenter>
						<Typography variant="body1">UGX</Typography> {/* Use Typography with a smaller variant */}
						<Typography variant="body1" sx={{ marginLeft: "4px" }}>
							{selected}
						</Typography>{" "}
						{/* Original selected value */}
					</FlexCenter>
				)}
				MenuProps={MenuProps}
			>
				{props.pricelist.map((value) => {
					return <MenuItem value={value.price}>{value.label}</MenuItem>;
				})}
			</Select>
		</FormControl>
	);
};

export default PriceFilterForm;
