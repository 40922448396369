import { useEffect, useState } from "react";
import { api } from "../API";
import {dummyData} from "../../utils/dummyData"

export const getPropertiesPage = async (pageParam = 1, options = {}) => {
	// const response = await api.get(`/properties?_page=${pageParam}`, options);
	console.log(dummyData);
	return dummyData;
};

const useProperties = (pageNum = 1) => {
	const [results, setResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState({});
	const [hasNextPage, setHasNextPage] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		setIsError(false);
		setError({});

		const controller = new AbortController();
		const { signal } = controller;

		getPropertiesPage(pageNum, { signal: signal })
			.then((data) => {
				setResults((prev) => [...prev, ...data]);
				setHasNextPage(Boolean(data.length));
				setIsLoading(false);
			})
			.catch((e) => {
				setIsLoading(false);
				if (signal.aborted) return;
				setIsError(true);
				setError({ message: e.message });
			});

		return () => controller.abort();
	}, [pageNum]);

	return { isLoading, isError, error, results, hasNextPage };
};

export default useProperties;
