import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import DummyCard from "../cards/DummyCard";

const ListingsLoader = () => {
	const items = Array.from({ length: 4 }, (_, i) => i);

	return (
		<Grid container spacing={4}>
			{items.map((item) => (
				<Grid xs={12} sm={4} md={3} key={item}>
					<DummyCard />
				</Grid>
			))}
		</Grid>
	);
};

export default ListingsLoader;
