import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {FlexBetween} from "../FlexBox"
import { Typography } from "@mui/material";
import Chip from "@mui/material/Chip";

const PropertyTypeForm = () => {
	return (
		<div>
			<FormGroup>
				<FlexBetween>
					<FormControlLabel sx={{ fontSize: "10px", color: "red" }} control={<Checkbox />} label="Residential" />
					<Typography>
						<Chip label="0" size="small" />
					</Typography>
				</FlexBetween>
				<FlexBetween>
					<FormControlLabel control={<Checkbox />} label="Commercial" />
					<Typography>
						<Chip label="0" size="small" />
					</Typography>
				</FlexBetween>
				<FlexBetween>
					<FormControlLabel control={<Checkbox />} label="Mixed" />
					<Typography>
						<Chip label="0" size="small" />
					</Typography>
				</FlexBetween>
			</FormGroup>
		</div>
	);
};

export default PropertyTypeForm;
