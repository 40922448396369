import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange, deepPurple } from "@mui/material/colors";

export default function LandLordAvatar() {
	return (
		<Stack direction="row" spacing={2}>
			<Avatar
				alt="Land lord avatar"
				sx={{ width: 45, height: 45 }}
				src="https://img.freepik.com/premium-photo/man-happy-african-american-senior-nursing-home-interior-looking-camera_100800-27018.jpg"
			/>
			{/* <Avatar sx={{ bgcolor: deepPurple[500] }}>OP</Avatar> */}
		</Stack>
	);
}
