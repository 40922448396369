import { Box, Container } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./scenes/NavBar";
import Listings from "./scenes/Listings";
import { ThemeProvider } from "@mui/material/styles";
import {siteTheme} from "./styles/theme"
import PropertyPage from "./scenes/PropertyPage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function App() {
	return (
		<ThemeProvider theme={siteTheme}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<Box className="app">
					<BrowserRouter>
						<Box>
							<NavBar />
							<Container fixed sx={{ padding: "30px 0", "&.MuiContainer-root": { paddingLeft: 0, paddingRight: 0 } }}>
								<Box>
									<Routes>
										<Route path="/" element={<Listings />} />
										<Route path="/property" element={<PropertyPage />} />
									</Routes>
								</Box>
							</Container>
						</Box>
					</BrowserRouter>
				</Box>
			</LocalizationProvider>
		</ThemeProvider>
	);
}

export default App;
