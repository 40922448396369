import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Chip } from "@mui/material";
import { FlexBetween, FlexStart } from "../FlexBox";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import CountertopsIcon from "@mui/icons-material/Countertops";
import ShowerIcon from "@mui/icons-material/Shower";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { FlexJustifyStart } from "../FlexBox";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const PropertyCard = React.forwardRef((props, ref) => {
	const [isSelected, setSelected] = React.useState(false);

	const handleFav = () => {
		setSelected(!isSelected);
	};

	const cardBody = (
		<>
			<CardMedia sx={{ height: 240, borderRadius: "8px" }} image={props.image}>
				<FlexBetween sx={{ padding: "10px" }}>
					<Chip label={props.posted} size="small" sx={{ backgroundColor: "#fff", color: "black" }} />
					<IconButton aria-label="favorite" size="large" onClick={handleFav}>
						{isSelected ? <FavoriteOutlinedIcon sx={{ color: "red" }} /> : <FavoriteBorderOutlinedIcon sx={{ color: "white", fill:"white"}} />}
					</IconButton>
				</FlexBetween>
			</CardMedia>
			<CardContent>
				<>
					<Typography gutterBottom sx={{ fontSize: "17px" }}>
						<strong>{props.rent.toLocaleString()} UGX</strong>/Month
					</Typography>
					<FlexBetween>
						<FlexStart>
							<LocationOnIcon sx={{ fontSize: "17px" }} />
							<Typography variant="body2">{props.location}</Typography>
						</FlexStart>
						<Link to={`/property?id=${props.id}`} target="_blank" rel="noopener noreferrer">
							<Typography sx={{ borderBottom: "1px dashed #000" }} variant="body2">
								View Details
							</Typography>
						</Link>
					</FlexBetween>

					{/* <Grid container spacing={1}>
						<Grid xs={12} sm={6}>
							<FlexJustifyStart>
								<NightShelterIcon />
								<Typography variant="body2">{props.bds}Bedrooms</Typography>
							</FlexJustifyStart>
						</Grid>
						<Grid xs={12} sm={6}>
							<FlexJustifyStart>
								<CountertopsIcon />
								<Typography variant="body2">{props.kitchen} Kitchen</Typography>
							</FlexJustifyStart>
						</Grid>
						<Grid xs={12} sm={6}>
							<FlexJustifyStart>
								<ShowerIcon />
								<Typography variant="body2">{props.bath} Bathrooms</Typography>
							</FlexJustifyStart>
						</Grid>
						<Grid xs={12} sm={6}>
							<FlexJustifyStart>
								<RequestQuoteIcon />
								<Typography variant="body2">{props.terms} months</Typography>
							</FlexJustifyStart>
						</Grid>
					</Grid> */}
				</>
			</CardContent>
		</>
	);

	const content = ref ? (
		<Card sx={{borderRadius:"8px" }} ref={ref}>
			{cardBody}
		</Card>
	) : (
		<Card sx={{borderRadius:"8px" }}>{cardBody}</Card>
	);

	return content;
});

export default PropertyCard;
