import { Button, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import {FlexBetween} from "../../components/FlexBox";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";


const NavBar = () => {
	const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
	const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

	const navStyle = {
		padding: { xs: "15px 30px", sm: "15px 80px" },
		borderBottom: "1px solid #e2e9eeff",
	};

	return (
		<FlexBetween sx={navStyle}>
			<FlexBetween>
				<Link to="/">
					<img src="/images/rent-beta-logo_light-04.png" className="navlogo" alt="rent beta logo"  />
				</Link>
			</FlexBetween>
			{isNonMobileScreens ? (
				<FlexBetween gap="2rem">
					<Typography>How it works</Typography>
					<Typography>FAQs</Typography>
				</FlexBetween>
			) : (
				<IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}>
					<MenuIcon />
				</IconButton>
			)}
		</FlexBetween>
	);
};

export default NavBar;
