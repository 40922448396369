export const dummyImages = [
	{ src: "/images/property/property1.jpg" },
	{ src: "/images/property/property3.jpg" },
	{ src: "/images/property/property4.jpg" },
	{ src: "/images/property/property5.jpg" },
	{ src: "/images/property/property6.jpg" },
	{ src: "/images/property/property7.jpg" },
	{ src: "/images/property/property8.jpg" },
	{ src: "/images/property/property9.jpg" },
	{ src: "/images/property/property10.jpg" },
	{ src: "/images/property/property11.jpg" },
	{ src: "/images/property/property6.jpg" },
	{ src: "/images/property/property7.jpg" },
	{ src: "/images/property/property8.jpg" },
];