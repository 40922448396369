import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
import { FlexBetween } from "../FlexBox";
import TextField from "@mui/material/TextField";
import PriceFilterForm from "../Forms/PriceFilterForm";

const pricelist = [
	{ price: 200000, label: "200,0000" },
	{ price: 300000, label: "300,0000" },
	{ price: 400000, label: "400,0000" },
	{ price: 500000, label: "500,0000" },
	{ price: 600000, label: "600,0000" },
	{ price: 700000, label: "700,0000" },
	{ price: 800000, label: "800,0000" },
	{ price: 900000, label: "900,0000" },
	{ price: 1000000, label: "1,000,0000" },
	{ price: 2000000, label: "2,000,0000" },
	{ price: 3000000, label: "3,000,0000" },
	{ price: 4000000, label: "4,000,0000" },
	{ price: 5000000, label: "5,000,0000" },
];

const PriceFilter = () => {
	return (
		<Stack spacing={3} sx={{ padding: "1rem" }}>
			<Typography variant="body2">Price Range</Typography>
			<Stack spacing={2} direction={{ xs: "column", sm: "row" }} sx={{ alignItems: "center" }}>
				<PriceFilterForm pricelist={pricelist} label="No Min" />
				<Typography variant="body2"> - </Typography>
				<PriceFilterForm pricelist={pricelist} label="No Max" />
			</Stack>
			<Button variant="contained" size="small">
				Apply
			</Button>
		</Stack>
	);
};

export default PriceFilter;
