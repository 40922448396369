import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LandLordAvatar from "../Avatars/LandLordAvatar";
import Divider from "@mui/material/Divider";
import { FlexEnd, FlexStart } from "../FlexBox";
import { Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const formatNumberString = (numberString) => {
	const number = Number(numberString);
	if (isNaN(number)) {
		return "Invalid number string";
	}
	return number.toLocaleString();
};

export default function PropertyDetailCard() {
	return (
		<Card sx={{ position: "sticky", top: 0 }}>
			<CardContent sx={{ padding: 0 }}>
				<FlexStart sx={{ padding: "16px" }}>
					<LandLordAvatar />
					<Box sx={{ marginLeft: "8px" }}>
						<Typography sx={{ fontSize: "14px", color: "#566a79" }}>Posted by</Typography>
						<Typography variant="body1" sx={{ fontSize: "17px" }}>
							Allan Katongole
						</Typography>
					</Box>
				</FlexStart>
				<Divider />
				<Stack spacing={3} sx={{ padding: "16px" }}>
					<FlexEnd>
						<Typography sx={{ marginRight: "8px", fontSize: "1.9rem", fontWeight: 700 }}>
							UGX {formatNumberString("1000000")} <span style={{ fontSize: "0.9rem", color: "#566a79" }}>Per month</span>
						</Typography>
					</FlexEnd>

					<DatePicker slotProps={{ textField: { size: "small" }}} label="Select move in date" />

					<Button fullWidth variant="contained">
						CONTACT LANDLORD
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
}
