import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Paper, Stack, Typography } from "@mui/material";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import { FlexCenter, FlexStart } from "../../components/FlexBox";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropertyDetailCard from "../../components/cards/PropertyDetailCard";
import PropertyImageGallery from "../../components/PropertyImageGallery";

const facilityIcons = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#52524e",
};

const PropertyPage = () => {
	return (
		<>
			<Grid container spacing={4}>
				<Grid xs={12} sm={8}>
					<Stack spacing={3} sx={{ marginBottom: "20px" }}>
						<PropertyImageGallery />
						{/* <Typography variant="h3">Ntinda Flats</Typography> */}
						<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
							<Typography variant="body1" sx={{ marginRight: "3px" }}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
								veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
								velit esse cillum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
								reprehenderit in voluptate velit esse cillum
							</Typography>
						</Box>
						<Typography variant="h5">Facilities</Typography>
						<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
							<Grid container spacing={2}>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<ChairOutlinedIcon sx={facilityIcons} />
											<Typography>Living Room</Typography>
										</FlexCenter>
										<FlexCenter>
											<BedOutlinedIcon sx={facilityIcons} />
											<Typography>Bedrooms</Typography>
										</FlexCenter>
										<FlexCenter>
											<CountertopsOutlinedIcon sx={facilityIcons} />
											<Typography>Kitchen</Typography>
										</FlexCenter>
										<FlexCenter>
											<ShowerOutlinedIcon sx={facilityIcons} />
											<Typography>Bathrooms</Typography>
										</FlexCenter>
									</Stack>
								</Grid>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<LockResetOutlinedIcon sx={facilityIcons} />
											<Typography>Security</Typography>
										</FlexCenter>
										<FlexCenter>
											<WifiOutlinedIcon sx={facilityIcons} />
											<Typography>Internet</Typography>
										</FlexCenter>
										<FlexCenter>
											<FitnessCenterOutlinedIcon sx={facilityIcons} />
											<Typography>Gym</Typography>
										</FlexCenter>
										<FlexCenter>
											<DirectionsCarFilledOutlinedIcon sx={facilityIcons} />
											<Typography>Parking Space</Typography>
										</FlexCenter>
									</Stack>
								</Grid>
							</Grid>
						</Box>
						<Typography variant="h5">Contract Type & Terms</Typography>
						<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
							<Stack spacing={3}>
								<FlexStart>
									<EventAvailableOutlinedIcon sx={{ fontSize: "22px", marginRight: "5px" }} />
									<Stack spacing={1}>
										<Typography variant="h5" sx={{ fontSize: "18px" }}>
											Monthly
										</Typography>
										<Typography>You will be required to pay the full month's rent at every end of a month cycle no matter when you move-in</Typography>
									</Stack>
								</FlexStart>
								<FlexStart>
									<PaymentsOutlinedIcon sx={{ fontSize: "22px", marginRight: "5px" }} />
									<Stack spacing={1}>
										<Typography variant="h5" sx={{ fontSize: "18px" }}>
											Advanced Payment
										</Typography>
										<Typography>You will be required to pay an advance of 3 months before moving in</Typography>
									</Stack>
								</FlexStart>
								<FlexStart>
									<BadgeOutlinedIcon sx={{ fontSize: "22px", marginRight: "5px" }} />
									<Stack spacing={1}>
										<Typography variant="h5" sx={{ fontSize: "18px" }}>
											Proof of identity
										</Typography>
										<Typography>Government issued ID, passport or driver’s license.</Typography>
									</Stack>
								</FlexStart>
							</Stack>
						</Box>
						<Typography variant="h5">How to book this property?</Typography>
						<Box>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>I like this property, how do I book it?</AccordionSummary>
								<AccordionDetails>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
								</AccordionDetails>
							</Accordion>
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>Can I view this property?</AccordionSummary>
								<AccordionDetails>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
								</AccordionDetails>
							</Accordion>
						</Box>
					</Stack>
				</Grid>
				<Grid sx={{ display: { xs: "none", sm: "block" } }} xs={12} sm={4}>
					<PropertyDetailCard />
				</Grid>
			</Grid>
			<Paper sx={{ display: { xs: "block", sm: "none" }, padding: "15px 10px", position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
				<Typography>Bottom</Typography>
			</Paper>
		</>
	);
};

export default PropertyPage;
