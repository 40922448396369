import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const DummyCard = () => {
	const cardBody = (
		<>
			<CardMedia sx={{ height: 240 }}>
				<Skeleton variant="rounded" height={240} />
			</CardMedia>
			<CardContent>
				<Stack spacing={2}>
					<Skeleton variant="rounded" height={20} />
					<Skeleton variant="rounded" height={20} />
					<Skeleton variant="rounded" height={20} />
				</Stack>
			</CardContent>
		</>
	);

	const content = <Card sx={{marginBottom:"15px"}}>{cardBody}</Card>;

	return content;
};

export default DummyCard;
