import React from 'react'
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomButton = styled(Button)({
	fontSize: 15,
	padding: "6px 12px",
	border: "1px solid",
	borderRadius: "8px",
	textTransform: "none",
	borderColor: "#cedbe3",
	color: "black",
	"&.MuiButton-root": {
		color: "black",
		"&:active": {
			color: "rgb(252 178 0)", // Color on click
		},
	},
	"&.MuiButton-root:hover": {
		borderColor: "rgb(252 178 0)",
		backgroundColor: "rgb(252 178 0 / 1%)",
	},
	"&:hover": {
		borderColor: "#0062cc",
	},
});

const FilterButton = (props) => {
  return (
			<CustomButton variant="outlined" disableElevation onClick={props.handleClick} endIcon={props.endIcon} startIcon={props.startIcon}>
				{props.children}
			</CustomButton>
		);
}

export default FilterButton