import * as React from "react";
import Box from "@mui/material/Box";
import PropertyTypeForm from "../Forms/PropertyTypeForm";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";



export default function PropertyTypeFilter() {
	return (
		<Box sx={{ padding: "0.5rem 0" }}>
			<Box sx={{ padding: "0 1rem" }}>
				<PropertyTypeForm />
			</Box>
         <Divider />
         <Box sx={{ padding: "0.8rem 1rem" }}>
            <Button variant="contained" size="small">Clear</Button>
         </Box>
		</Box>
	);
}
