import { createTheme } from "@mui/material/styles";

export const siteTheme = createTheme({
	palette: {
		primary: {
			main: "#fcb200", // Example primary color
		},
		secondary: {
			main: "#cc9f37", // Example secondary color
		},
		error: {
			main: "#f44336", // Example error color
		},
		background: {
			default: "#ffffff", // Default background color
		},
	},
	typography: {
		fontFamily: ["Inter", "sans-serif"].join(","),
		h1: {
			fontFamily: ["Inter", "sans-serif"].join(","),
			fontSize: 40,
			fontWeight: 700,
		},
		h2: {
			fontFamily: ["Inter", "sans-serif"].join(","),
			fontSize: 32,
			fontWeight: 700,
			color: "rgba(0, 0, 0, 0.87)",
		},
		h3: {
			fontFamily: ["Inter", "sans-serif"].join(","),
			fontSize: 26,
			fontWeight: 700,
			color: "rgba(0, 0, 0, 0.87)",
		},
		h4: {
			fontFamily: ["Inter", "sans-serif"].join(","),
			fontSize: 22,
			fontWeight: 700,
			color: "rgba(0, 0, 0, 0.87)",
		},
		h5: {
			fontFamily: ["Inter", "sans-serif"].join(","),
			fontSize: 18,
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: 700,
		},
		h6: {
			fontFamily: ["Inter", "sans-serif"].join(","),
			fontSize: 15,
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: 700,
		},
		body1: {
			fontFamily: ["Inter", "sans-serif"].join(","),
			fontSize: "1rem",
			color: "rgba(0, 0, 0, 0.87)",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					boxShadow: "none", // Remove box shadow
					fontSize: "15px",
					fontWeight: 700,
					color:"white"
				},
			},
		},
	},
});