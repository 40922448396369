export const dummyData = [
	{
		id: 1,
		posted: "8 days ago",
		image:
			"https://housinganywhere.imgix.net/unit_type/1513533/53e2e811-373d-4173-a2d9-1701e9bfa352.jpg?ixlib=react-9.8.1&auto=format&fit=crop&ar=1:1&q=35&w=280&h=280&dpr=1",
		rent: "1200000",
		bds: 4,
		bath: 2,
		kitchen: 1,
		terms: 5,
		location: "Kisasi",
	},
	{
		id: 2,
		posted: "4 hours ago",
		image: "https://spectrumproperties.co.ug/wp-content/uploads/2021/05/Cheap-house-for-sale-in-Kira-Uganda-6-1-592x444.jpg",
		rent: "500000",
		bds: 1,
		bath: 1,
		kitchen: 1,
		terms: 3,
		location: "Kira",
	},
	{
		id: 3,
		posted: "6 days ago",
		image: "https://qph.cf2.quoracdn.net/main-qimg-ca31bd9c6189356ca430b917f66f973e-lq",
		rent: "600000",
		bds: 2,
		bath: 1,
		kitchen: 1,
		terms: 3,
		location: "Makindye Luwafu",
	},
	{
		id: 4,
		posted: "8 days ago",
		image:
			"https://housinganywhere.imgix.net/unit_type/1495967/fceaf231-4a86-4eaf-b859-68ed795b4abc.jpg?ixlib=react-9.8.1&auto=format&fit=crop&ar=1:1&q=35&w=280&h=280&dpr=1",
		rent: "1000000",
		bds: 4,
		bath: 2,
		kitchen: 1,
		terms: 3,
		location: "Nansana",
	},
	{
		id: 5,
		posted: "7 days ago",
		image: "https://www.realestatedatabase.net/PropertyPhotos/341/202108240917389010_202108240658412729.jpeg",
		rent: "1200000",
		bds: 3,
		bath: 2,
		kitchen: 1,
		terms: 3,
		location: "Ntinda",
	},
	{
		id: 6,
		posted: "3 days ago",
		image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFE1153jkthATg8W3kU61LEXIXx4gyylGzlmrqkUdzhQ&s",
		rent: "1200000",
		bds: 3,
		bath: 2,
		kitchen: 1,
		terms: 3,
		location: "Kisasi",
	},
	{
		id: 7,
		posted: "4 days ago",
		image:
			"https://housinganywhere.imgix.net/unit_type/1453460/e3aaf4b7-261b-4612-9026-1a06d2bc6616.jpg?ixlib=react-9.8.1&auto=format&fit=crop&ar=1:1&q=35&w=280&h=280&dpr=1",
		rent: "1200000",
		bds: 3,
		bath: 2,
		kitchen: 1,
		terms: 3,
		location: "Naalya",
	},
	{
		id: 8,
		posted: "12 days ago",
		image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQkoGlSECUP6fhlFQE4ONKyouADisk2bG6uTKy72YZ0w&s",
		rent: "1200000",
		bds: 3,
		bath: 2,
		kitchen: 1,
		terms: 3,
		location: "Nansana",
	},
	{
		id: 9,
		posted: "8 days ago",
		image:
			"https://housinganywhere.imgix.net/unit_type/1513533/53e2e811-373d-4173-a2d9-1701e9bfa352.jpg?ixlib=react-9.8.1&auto=format&fit=crop&ar=1:1&q=35&w=280&h=280&dpr=1",
		rent: "1200000",
		bds: 4,
		bath: 2,
		kitchen: 1,
		terms: 5,
		location: "Kisasi",
	},
	{
		id: 10,
		posted: "4 hours ago",
		image: "https://spectrumproperties.co.ug/wp-content/uploads/2021/05/Cheap-house-for-sale-in-Kira-Uganda-6-1-592x444.jpg",
		rent: "500000",
		bds: 1,
		bath: 1,
		kitchen: 1,
		terms: 3,
		location: "Ntinda",
	},
	{
		id: 11,
		posted: "6 days ago",
		image: "https://qph.cf2.quoracdn.net/main-qimg-ca31bd9c6189356ca430b917f66f973e-lq",
		rent: "600000",
		bds: 2,
		bath: 1,
		kitchen: 1,
		terms: 3,
		location: "Kiwatule",
	},
	{
		id: 12,
		posted: "8 days ago",
		image:
			"https://housinganywhere.imgix.net/unit_type/1495967/fceaf231-4a86-4eaf-b859-68ed795b4abc.jpg?ixlib=react-9.8.1&auto=format&fit=crop&ar=1:1&q=35&w=280&h=280&dpr=1",
		rent: "1000000",
		bds: 4,
		bath: 2,
		kitchen: 1,
		terms: 3,
		location: "Naalya",
	},
];